import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import logo from "../img/LOGO_G.svg";
import zahon from "../img/kytka.svg";
import ker from "../img/ker.svg";
import vozik from "../img/sekacka.svg";
import strom from "../img/strom.svg";

export const IndexPageTemplate = ({
                                    image,
                                    title,
                                    heading,
                                    description,
                                    subheading,
                                    mainpitch,
                                    intro
                                  }) => {
  const features = [
    {
      id: 1,
      img: zahon,
      text: ['Péče o travnaté plochy', 'Zakládání nových travnatých ploch']
    },
    {
      id: 2,
      img: ker,
      text: ['Výsadba květinových záhonů', 'Údržba květinových záhonů']
    },
    {
      id: 3,
      img: vozik,
      text: ['Průklesty, kácení a odstranění pařezů', 'Pokládka ztracených obrubníků', 'Instalace automatických zavlažovacích systémů']
    },
    {
      id: 4,
      img: strom,
      text: ['Výsadba keřů, stromů', 'Tvarování živých plotů', 'Řez keřů a stromů']
    }
  ]
  return (<div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <img src={logo} alt="Jan Všetečka logo"
             style={{ height: '100%', background: 'radial-gradient(rgba(57, 57, 57, 0.6), transparent)' }}/>
      </div>
    </div>
    <section className="section section--gradient has-background-light">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div className="tile">
                <h1 className="has-text-weight-semibold is-size-2">{mainpitch.title}</h1>
              </div>
              <p className="is-size-5">{mainpitch.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <h2 className="vsetecka-color2">Naše služby</h2>
              <div className="columns is-multiline">
                {features.map(item => (
                  <div key={item.id} className="column is-6">
                    <div className="">
                      <div>
                        <ul style={{ textAlign: 'left' }}>
                          {item.text.map(textItem => (
                            <li key={textItem}>{textItem}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="has-text-centered">
                        <div
                          style={{
                            width: '140px',
                            height: '140px',
                            display: 'inline-flex',
                            marginTop: '30px'
                          }}
                        >
                          <img src={item.img} style={{ height: '100%', width: '100%' }} alt="ikona"/>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient has-background-light">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="column is-12">
              <h3 className="has-text-weight-semibold is-size-2">
                Realizace
              </h3>
              <BlogRoll/>
              <div className="column is-12 has-text-centered">
                <Link className="btn" to="/realizace">
                  Více
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>)
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainpitch {
          title
          description
        }
      }
    }
  }
`
